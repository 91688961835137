import { type FC, memo } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'
import { Center } from '../../components'
import SignInForm from '../SignInForm'
import Loading from './Loading'

const Protected: FC<{ children: any }> = ({ children }) => {
    const [user, loading] = useAuthState(auth)

    if (user === null) {
        if (loading) {
            return (
                <Center>
                    <Loading />
                </Center>
            )
        }

        return (
            <Center>
                <SignInForm />
            </Center>
        )
    }

    return children
}

export default memo(Protected)
