import { httpsCallable } from 'firebase/functions'
import { functions } from '../firebase'

export interface ResponseData {
    message: string
}

const helloWorld = httpsCallable<undefined, ResponseData>(functions, 'helloWorld')

export default helloWorld
