import Center from '../components/Center'

const Analytics = () => {
    return (
        <Center>
            <div>Analytics</div>
        </Center>
    )
}

export default Analytics
