import { type FC, memo } from 'react'
import { Center } from '../components'

const NotFound: FC = () => {
    return (
        <Center>
            Not Found
        </Center>
    )
}

export default memo(NotFound)
