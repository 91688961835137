import { createTheme, type ThemeOptions } from '@mui/material'
import { type FirebaseOptions } from 'firebase/app'

export const nodeEnv = process.env.NODE_ENV

export const version = process.env.REACT_APP_VERSION

export const functionDomain = process.env.REACT_APP_FUNCTION_DOMAIN

export const firebaseConfig: FirebaseOptions = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

const options: ThemeOptions = {
    palette: {
        mode: 'dark'
    },
    components: {
        MuiButton: {
            defaultProps: {
                variant: 'outlined'
            }
        }
    }
}

export const theme = createTheme(options)
