import { Center } from '../components'

const Tools = () => {
    return (
        <Center>
            <div>Tools</div>
        </Center>
    )
}

export default Tools
